@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'dreamavenue';
    src: url('./assets/fonts/dreamavenue.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}


/* If you're not using Tailwind, add this to your CSS file */
.hero-section {
    background-image: url('https://res.cloudinary.com/dlhwfesiz/image/upload/v1729019935/samantha-gades-x40Q9jrEVT0-unsplash_ssbiod.jpg');
    /* Replace with actual image path */
    background-size: cover;
    background-position: center;
}


/* Customizing the slider arrow buttons */
.slick-prev,
.slick-next {
    /* background-color: rgba(0, 0, 0, 0.5) !important; */
    /* Semi-transparent black background */
    color: white !important;
    /* White arrow color */
    font-size: 0px !important;
    /* Make the arrows larger */
    /* width: 40px !important; */
    /* Set the width of the button */
    /* height: 40px !important; */
    /* Set the height of the button */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
    /* Make the buttons circular */
    z-index: 1 !important;
    /* Make sure the buttons are above the images */
    /* position: absolute !important; */
    /* Position the buttons absolutely */
    top: 50% !important;
    /* Center the buttons vertically */
    /* transform: translateY(-50%) !important; */
    /* Adjust for the centering */
}

.slick-prev:hover,
.slick-next:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    /* Darken the background on hover */
}

.slick-prev {
    left: -20px !important;
    /* Position to the left of the slider */
}

.slick-next {
    right: -20px !important;
    /* Position to the right of the slider */
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
.scrollbar-hide {
    scrollbar-width: none;
}

/* Hide scrollbar for Internet Explorer, Edge */
.scrollbar-hide {
    -ms-overflow-style: none;
}

/* In your CSS file */
.no-scroll {
    overflow: hidden;
}




/* Make navigation arrows smaller and black */
.custom-nav {
    width: 30px;
    height: 30px;
    /* background-color: black; */
    /* border-radius: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 14px;
}


/* Positioning the arrows */
.swiper-button-prev {
    left: 5px;
}

.swiper-button-next {
    right: 5px;
}

/* Hide default arrows */
.swiper-button-prev::after,
.swiper-button-next::after {
    display: none;
}

/* Hover effect */
.custom-nav:hover {
    opacity: 1;
    cursor: pointer;
}